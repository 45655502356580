import React, { useRef, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Form, Input } from 'antd';
import querySearch from 'stringquery';
import classnames from 'classnames';

import { sendSegment } from 'utils/segment';
import { passwordRule, samePassword } from 'utils/forms';
import { changePassword, setNewPassword } from 'services/auth';
import { userLogin, getSignInPath } from 'utils/login';
import {
  AT_LEAST_ONE_DIGIT,
  AT_LEAST_EIGHT_LETTERS,
  AT_LEAST_ONE_UPPERCASE,
  AT_LEAST_ONE_SPECIAL_CHAR
} from 'constants/Forms';
import { PASSWORD_VALIDATION_RULES } from 'constants/auth';
import { useModal } from 'contexts/modalProvider.context';

import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import RegistrationContainer from 'components/RegistrationContainer/RegistrationContainer';
import { ReactComponent as Check } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as Clear } from 'assets/bosta-icons/Circle-clear.svg';

import './ResetPassword.less';

const ResetPassword = ({ intl, history, passwordExpired }) => {
  const newPasswordFormRef = useRef();
  const { openModal } = useModal();

  const [showPasswordValidation, setShowPasswordValidation] = useState({
    atLeastEightChars: false,
    containsNumber: false,
    atLeastOneUpperCase: false,
    atLeastOneSpecialChar: false,
    showBothFields: false,
    showSuccessValidationMessage: false
  });
  const [focusCount, setFocusCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const handleSubmit = async ({ oldPassword, password }) => {
    try {
      setIsLoading(true);
      let path = history.location.pathname;
      path = path.split('/');
      const token = path[path.length - 1];

      await (passwordExpired
        ? changePassword({
            email: userInfo?.email,
            oldPassword,
            newPassword: password
          })
        : setNewPassword(token, password));

      sendSegment('E_NEW_PASSWORD_SET');
      notify({
        msg: intl.formatMessage({
          id: 'login.set_new_passowrd.password_changes_sucessfuly'
        }),
        type: 'success'
      });
      window.history.replaceState(null, null, getSignInPath());
      userLogin({
        email: userInfo?.email,
        password,
        setIsLoading,
        intl,
        history,
        openModal
      });
    } catch (error) {
      setIsLoading(false);
      notify({ msg: error.message, error });
    }
  };

  useEffect(() => {
    const { location } = history;
    const query = querySearch(location.search);
    const userName = query.name || '';
    setUserInfo({
      firstName: userName.split('_')[0],
      lastName: userName.split('_')[1] || '_',
      fullName: userName.replace(/_/g, ' '),
      email: query.email || ''
    });
  }, [history]);

  const checkPasswordValidation = async ({ target: { value } }) => {
    const atLeastEightChars = AT_LEAST_EIGHT_LETTERS.test(value);
    const containsNumber = AT_LEAST_ONE_DIGIT.test(value);
    const atLeastOneUpperCase = AT_LEAST_ONE_UPPERCASE.test(value);
    const atLeastOneSpecialChar = AT_LEAST_ONE_SPECIAL_CHAR.test(value);

    const isValid =
      containsNumber &&
      atLeastEightChars &&
      atLeastOneUpperCase &&
      atLeastOneSpecialChar;

    setShowPasswordValidation({
      atLeastEightChars,
      containsNumber,
      atLeastOneUpperCase,
      atLeastOneSpecialChar,
      showBothFields: !isValid,
      showSuccessValidationMessage: isValid
    });
    setFocusCount(focusCount + 1);
  };

  const handleShowValidationMessages = () => {
    const passwordValue = newPasswordFormRef.current?.getFieldValue('password');
    setShowPasswordValidation({
      ...showPasswordValidation,
      showBothFields:
        focusCount === 0 ? true : showPasswordValidation.showBothFields,
      showSuccessValidationMessage: !showPasswordValidation.showBothFields
    });
    setFocusCount(focusCount === 0 && !passwordValue ? 0 : focusCount + 1);
  };

  const handleHideValidationMessages = () => {
    setShowPasswordValidation({
      ...showPasswordValidation,
      showSuccessValidationMessage: false
    });
  };

  return (
    <RegistrationContainer
      hideTrackOrderInHeader
      isLoading={isLoading}
      tip={intl.formatMessage({ id: 'login.set_new_passowrd.please_wait' })}
      className="br-reset-password-container"
      content={
        <>
          <span className="display-md">
            {intl.formatMessage({
              id: `login.set_new_passowrd.${
                passwordExpired ? 'expired_title' : 'title'
              }`
            })}
          </span>
          <span className="br-reset-password__subtitle">
            {intl.formatMessage({ id: 'login.set_new_passowrd.subtitle' })}
          </span>
          <span className="br-reset-password_user-section">
            <span className="br-reset-password_user-name-circle body-medium">
              {userInfo?.firstName?.charAt(0).toUpperCase() || ''}
              {userInfo?.lastName?.charAt(0).toUpperCase() || ''}
            </span>
            <span className="br-reset-password_user-data">
              <span className="br-login__user-name body-medium">
                {userInfo?.fullName}
              </span>
              <span className="br-reset-password__user-email">
                {userInfo.email}
              </span>
            </span>
          </span>
          <Form
            className="reset-password-form"
            ref={newPasswordFormRef}
            onFinish={handleSubmit}
          >
            {passwordExpired && (
              <Form.Item
                className="br-reset-password__form-confirm-password"
                name="oldPassword"
                dependencies={['password']}
                label={intl.formatMessage({
                  id: 'login.set_new_passowrd.current_password'
                })}
                rules={[{ required: true }]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
            )}
            <Form.Item
              name="password"
              rules={[{ required: true }, passwordRule()]}
              label={intl.formatMessage({
                id: 'login.set_new_passowrd.new_password'
              })}
              className={classnames('br-reset-password__password-input')}
              help={
                showPasswordValidation.showBothFields ? (
                  <>
                    {PASSWORD_VALIDATION_RULES.map(({ label, value }) => (
                      <div
                        className={classnames(
                          'caption',
                          'br-reset-password__password-validation',
                          {
                            'br-reset-password__password-validation--is-valid':
                              showPasswordValidation[value] && focusCount !== 0
                          },
                          {
                            'br-reset-password__password-validation--is-not-valid':
                              !showPasswordValidation[value] && focusCount !== 0
                          }
                        )}
                      >
                        {!showPasswordValidation[value] && focusCount !== 0 ? (
                          <Clear />
                        ) : (
                          <Check />
                        )}
                        {label}
                      </div>
                    ))}
                  </>
                ) : showPasswordValidation.showSuccessValidationMessage ? (
                  <div className="br-reset-password__validation-success caption">
                    <Check />
                    {intl.formatMessage({
                      id: 'login.set_new_passowrd.validation_success'
                    })}
                  </div>
                ) : undefined
              }
            >
              <Input.Password
                onChange={checkPasswordValidation}
                onFocus={handleShowValidationMessages}
                onBlur={handleHideValidationMessages}
              />
            </Form.Item>

            <Form.Item
              className="br-reset-password__form-confirm-password"
              name="confirmPassword"
              dependencies={['password']}
              label={intl.formatMessage({
                id: 'login.set_new_passowrd.confirm_new_password'
              })}
              rules={[
                { required: true },
                ({ getFieldValue }) => {
                  return samePassword(
                    intl.formatMessage({
                      id: 'login.set_new_passowrd.confirm_password_not_match'
                    }),
                    getFieldValue('password')
                  );
                }
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <BRButton
              type="primary"
              className="button-lg br-registration-form-card__submit-btn"
              htmlType="submit"
              label={intl.formatMessage({ id: 'login.actions.continue' })}
            />
          </Form>
        </>
      }
    />
  );
};
export default injectIntl(withRouter(ResetPassword));
