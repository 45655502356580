import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

import { DEVICE_FINGERPRINT_KEY } from 'constants/common';
import errorHandler from 'utils/error-reporting';

export const handleFingerPrint = async () => {
  const foundedKey = localStorage.getItem(DEVICE_FINGERPRINT_KEY);
  if (foundedKey) {
    return foundedKey;
  }
  try {
    const result = await getFingerprint();
    localStorage.setItem(DEVICE_FINGERPRINT_KEY, result);
  } catch (error) {
    errorHandler.report(error);
  }
};
