import React, { createContext, useCallback, useEffect, useState } from 'react';

export const NotificationWrapper = createContext({});

export const NotificationProvider = ({ children }) => {
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [syncingProgress, setSyncingProgress] = useState(0);

  const getNotification = useCallback(
    (event) => {
      const message = event?.data?.data?.body || '';
      setNotificationMessage(message);
    },
    [notificationMessage]
  );

  useEffect(() => {
    if (syncingProgress > 0 && syncingProgress <= 100) {
      const runFunction = () => {
        if (syncingProgress < 97) {
          setSyncingProgress(syncingProgress + 1);
        }
      };
      const intervalId = setInterval(() => {
        runFunction();
      }, 500);
      return () => {
        clearInterval(intervalId);
      };
    }
    if (syncingProgress === 100) {
      setSyncingProgress(0);
    }
  }, [syncingProgress]);

  useEffect(() => {
    navigator.serviceWorker &&
      navigator.serviceWorker.addEventListener('message', getNotification);

    return () => {
      navigator.serviceWorker &&
        navigator.serviceWorker.removeEventListener('message', getNotification);
    };
  }, [getNotification]);

  return (
    <NotificationWrapper.Provider
      value={{
        notificationMessage,
        setNotificationMessage,
        syncingProgress,
        setSyncingProgress
      }}
    >
      {children}
    </NotificationWrapper.Provider>
  );
};
