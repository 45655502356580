import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import Icon from '@ant-design/icons';
import classnames from 'classnames';

import { useModal } from 'contexts/modalProvider.context';
import { sendSegment } from 'utils/segment';

import GoogleMapModal from 'components/BRGoogleMap/GoogleMapModal/GoogleMapModal';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as InitialMarker } from 'assets/bosta-icons/google-map-marker-initial.svg';
import { ReactComponent as SuccessMarker } from 'assets/bosta-icons/google-map-marker-success.svg';

import './GoogleMapCard.less';

const GoogleMapCard = ({
  setUserPosition,
  setUserAddress,
  setResponse,
  isMapUsed,
  intl,
  optional,
  confirmLocationEventTracking,
  allAreas,
  isGeoLocationRequired,
  userPositionError
}) => {
  const [position, setPosition] = useState(null);

  const { openModal } = useModal();
  
  const handleOpenMapModal = () => {
    sendSegment('E_PICKUP_LOCATION_SET_LOCATION');

    openModal(GoogleMapModal, {
      isMapUsed: isMapUsed,
      position,
      confirmLocationEventTracking: confirmLocationEventTracking,
      allAreas,
      isGeoLocationRequired,
      onSuccess: ({ userAddress, userPosition, selectedDistrictId }) => {
        setPosition(userPosition);
        setUserPosition(userPosition);

        if (!isGeoLocationRequired) {
          setUserAddress(userAddress);
          setResponse(selectedDistrictId);
        }
      }
    });
  };

  return (
    <div className="br-google-map-card__container">
      <div
        className={classnames('br-google-map-card', {
          'br-google-map-card__error': userPositionError
        })}
        onClick={handleOpenMapModal}
      >
        <BRContentHeader
          title={
            <>
              {intl.formatMessage({
                id: 'br_google_map.title'
              })}
              {optional && (
                <span className="br-form-count__optional-label">
                  {intl.formatMessage({
                    id: 'form.optional_label'
                  })}
                </span>
              )}
            </>
          }
          subtitle={intl.formatMessage({
            id: 'br_google_map.subtitle'
          })}
          titleClassName="body-medium"
          className="br-google-map-card__header"
        />
        <BRButton
          type="outline"
          label={intl.formatMessage({
            id: `br_google_map.actions.${
              isMapUsed ? 'refine_location' : 'set_location'
            }`
          })}
        />
        <div className="br-google-map-card__icon-container">
          <Icon
            component={isMapUsed ? SuccessMarker : InitialMarker}
            className="br-google-map-card__icon"
          />
        </div>
      </div>
      {userPositionError && (
        <span className="br-google-map-card__error-message">
          {intl.formatMessage({ id: 'br_google_map.location_required' })}
        </span>
      )}
    </div>
  );
};

export default injectIntl(GoogleMapCard);
