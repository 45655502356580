import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { COUNTRY_CURRENCY } from './helpers';
import { formatNumbersBasedOnLanguage } from 'utils/intl-wrapper';
import { LOCALE } from './intl-wrapper';
import {
  TODAY,
  YESTERDAY,
  LAST_WEEK,
  LAST_MONTH,
  LAST_YEAR,
  THIS_WEEK_TO_DATE,
  THIS_MONTH_TO_DATE,
  THIS_YEAR_TO_DATE
} from './date-picker';
import { getLocalizedValue } from 'utils/bilingual-values';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as FileUploadIcon } from 'assets/bosta-icons/File-upload.svg';
import { ReactComponent as SaveForLaterIcon } from 'assets/bosta-icons/save-for-later.svg';
import { ReactComponent as Facebook } from 'assets/bosta-icons/facebook-shop.svg';
import { ReactComponent as FacebookMessenger } from 'assets/bosta-icons/facebook-messenger.svg';
import { ReactComponent as Instagram } from 'assets/bosta-icons/instagram.svg';
import { ReactComponent as FacebookGray } from 'assets/bosta-icons/facebook-gray.svg';
import { ReactComponent as FacebookMessengerGray } from 'assets/bosta-icons/facebook-messenger-gray.svg';
import { ReactComponent as InstagramGray } from 'assets/bosta-icons/instagram-gray.svg';
import { ReactComponent as CursorClickIcon } from 'assets/bosta-icons/cursor-click.svg';
import { ReactComponent as ClockIcon } from 'assets/bosta-icons/Clock.svg';
import { ReactComponent as OrdersIcon } from 'assets/bosta-icons/Orders.svg';
import { ReactComponent as PickupIcon } from 'assets/bosta-icons/Pickups.svg';
import { ReactComponent as BankNoteIcon } from 'assets/bosta-icons/bank-note.svg';
import { ReactComponent as ShieldTickTealIcon } from 'assets/bosta-icons/shield-tick-teal.svg';
import { ReactComponent as ImageTealIcon } from 'assets/bosta-icons/image-teal.svg';
import { ReactComponent as KeyTealIcon } from 'assets/bosta-icons/key-teal.svg';

const locale = getLocaleFromLocalStorage();

export const ADD_PRODUCTS_MODAL_CONTENT = [
  {
    icon: <FileUploadIcon />,
    title: fmt({ id: 'products.add_products_modal.single_title' }),
    subtitle: fmt({ id: 'products.add_products_modal.single_subtitle' })
  },
  {
    icon: <SaveForLaterIcon />,
    title: fmt({ id: 'products.add_products_modal.multiple_title' }),
    subtitle: fmt({ id: 'products.add_products_modal.multiple_subtitle' })
  }
];

export const PRODUCTS_QUICK_FILTERS = [
  {
    label: fmt({
      id: 'products.products_table.quick_filters.all'
    }),
    value: undefined
  },
  {
    label: fmt({
      id: 'products.products_table.quick_filters.active'
    }),
    value: 'activate'
  },
  {
    label: fmt({
      id: 'products.products_table.quick_filters.inactive'
    }),
    value: 'deactivate'
  },
  {
    label: fmt({
      id: 'products.products_table.quick_filters.low_stock'
    }),
    value: 'lowStock'
  }
];

export const ACTIVE_OPTIONS = {
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  LOW_STOCK: 'lowStock'
};

export const SLIDE_OVER = {
  MAIN_IMAGE_INDEX: 0,
  MAX_IMAGES_NUMBER_INDEX: 4,
  MAX_IMAGES_NUMBER: 5
};

export const SLIDE_OVER_ACTIONS = {
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
  SELECT: 'select'
};

export const CATEGORIES_ERROR_MSG_CODES = {
  12011: fmt({
    id: 'products.add_products.price.category_field.error_msgs.category_exists'
  })
};

export const CATEGORIES_TYPES = {
  DEFAULT: 'default',
  CUSTOM: 'custom'
};

export const IMAGE_MAX_SIZE_BY_MB = 10; // MB => Megabyte

export const SLLR = 'sllr.co/';

export const ACCEPTED_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

export const SHEET_MAX_NUM_OF_PRODUCTS = 500;

export const IMPORT_MULTIPLE_PRODUCTS_HELP_LINK = `https://bosta.freshdesk.com/${locale}/support/solutions/articles/36000385776-importing-multiple-products`;

export const MULTIPLE_IMPORT_STEPS_KEYS = {
  UPLOAD_FILE: 'UPLOAD_FILE',
  PREVIEW_FILE: 'PREVIEW_FILE',
  VALIDATING_AND_UPLOADING: 'VALIDATING_AND_UPLOADING',
  PREVIEW_ERRORS: 'PREVIEW_ERRORS'
};

export const FILE_SHEET_UPLOAD_ERROR_TYPES = {
  INVALID_FILE: 'INVALID_FILE',
  EMPTY_FILE: 'EMPTY_FILE',
  EXCEEDED_MAX_ROWS: 'EXCEEDED_MAX_ROWS'
};

export const EMPTY_STATE = {
  sllr_store_table: 'sllr_store_table',
  sllr_store_analytics: 'sllr_store_analytics',
  catalog_table_mobile: 'catalog_table_mobile',
  catalog_tableAR_mobile: 'catalog_tableAR_mobile',
  catalog_table: 'catalog_table',
  catalog_tableAR: 'catalog_tableAR',
  empty_card1: 'empty_card1'
};

export const MAX_PRODUCT_NAME_COUNT = 256;

export const PRODUCT_RETURN_TYPE = 'return';

export const OPTION_LIST_TYPE = 'DragableOptionList';

export const DONE = 'done';
export const VARIANT_PRICE = 'variantPrice';
export const VARIANT_ORIGINAL_PRICE = 'variantOriginalPrice';

export const PRODUCT_PAGE_TYPE = {
  CREATE: 'create',
  DUPLICATE: 'duplicate',
  EDIT: 'edit'
};

export const PRODUCT_VARIANTS_KEY = {
  PRODUCT_VARIANCES: 'productVariances',
  EDITED_VARIANCES: 'editedVariances'
};

export const PRODUCT_OPTIONS_LABEL = {
  SIZE: fmt({
    id: 'products.add_products.product_options.options.size'
  }),
  COLOR: fmt({
    id: 'products.add_products.product_options.options.color'
  }),
  BRAND: fmt({
    id: 'products.add_products.product_options.options.brand'
  }),
  CONDITION: fmt({
    id: 'products.add_products.product_options.options.condition'
  }),
  MATERIAL: fmt({
    id: 'products.add_products.product_options.options.material'
  }),
  STORAGE: fmt({
    id: 'products.add_products.product_options.options.storage'
  })
};

export const PRODUCT_OPTIONS = [
  {
    label: PRODUCT_OPTIONS_LABEL.SIZE,
    value: 'Size'
  },
  {
    label: PRODUCT_OPTIONS_LABEL.COLOR,
    value: 'Color'
  },
  {
    label: PRODUCT_OPTIONS_LABEL.BRAND,
    value: 'Brand'
  },
  {
    label: PRODUCT_OPTIONS_LABEL.CONDITION,
    value: 'Condition'
  },
  {
    label: PRODUCT_OPTIONS_LABEL.MATERIAL,
    value: 'Material'
  },
  {
    label: PRODUCT_OPTIONS_LABEL.STORAGE,
    value: 'Storage'
  }
];

export const PRODUCT_OPTIONS_PLACEHOLDER = {
  Size: fmt({
    id: 'products.add_products.product_options.options.size_placeholder'
  }),
  Color: fmt({
    id: 'products.add_products.product_options.options.color_placeholder'
  }),
  Brand: fmt({
    id: 'products.add_products.product_options.options.brand_placeholder'
  }),
  Condition: fmt({
    id: 'products.add_products.product_options.options.condition_placeholder'
  }),
  Material: fmt({
    id: 'products.add_products.product_options.options.material_placeholder'
  }),
  Storage: fmt({
    id: 'products.add_products.product_options.options.storage_placeholder'
  })
};

export const PRICE_TYPES = {
  DEFAULT: 'default',
  ORIGINAL: 'original'
};

export const FILE_LIST_TYPE = 'DragableUploadList';

export const BOSTA_SLLR_LOGO = 'BostaSllrLogo';
export const FLYERS_DASHBOARD = 'FLYERS_DASHBOARD';

export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const CHANGE = 'CHANGE';

export const FEATURES_TOUR_KEYS = {
  SLLR_SETTINGS_TOUR: 'isSllrSettingsTourViewed',
  SHIPPING_ORDERS_TOUR: 'isShippingOrdersTourViewed',
  START_SELLING_TOUR: 'isStartSellingTourViewed',
  ORDERS_PAGE_TOUR: 'isOrdersPageTourViewed'
};

export const ASCENDING = 'asc';
export const DESCENDING = 'desc';

export const DIRECTION = {
  UP: 'up',
  DOWN: 'down'
};

export const SHIPPING = 'shipping';
export const POLICIES = 'policies';
export const PUDO = 'pudo';
export const GENERAL = 'general';
export const PAYMENTS = 'payments';
export const DOMAINS = 'domains';
export const DIFF_DAYS_TO_SHOW_INCREASE_MODAL = 2;
export const EMPTY_RICH_FORMATTER_TEXT = '<p><br></p>';
export const LESS_THAN = 'lessThan';
export const ALWAYS = 'always';
export const LOW_STOCK_DEFAULT = 5;

export const UPLOAD_IMAGE_MODAL_POINTS = [
  {
    title: fmt({ id: 'products.upload_image_modal.points.point_one.title' }),
    subtitle: fmt({
      id: 'products.upload_image_modal.points.point_one.subtitle'
    }),
    icon: <ShieldTickTealIcon className="ant-icon-lg" />
  },
  {
    title: fmt({ id: 'products.upload_image_modal.points.point_two.title' }),
    subtitle: fmt({
      id: 'products.upload_image_modal.points.point_two.subtitle'
    }),
    icon: <ImageTealIcon className="ant-icon-lg" />
  },
  {
    title: fmt({ id: 'products.upload_image_modal.points.point_three.title' }),
    subtitle: fmt({
      id: 'products.upload_image_modal.points.point_three.subtitle'
    }),
    icon: <KeyTealIcon className="ant-icon-lg" />
  }
];

export const FILE_TYPE_MAPPER = {
  '.png': 'image/png',
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpg',
  '.pdf': 'application/pdf'
};

export const getProductName = ({ name, nameAr }) =>
  getLocalizedValue({ value: name, valueAr: nameAr });
