import {
  BUSINESS_SUB_ACCOUNT,
  bannerModalPermission
} from 'constants/teams-and-members';
import aclMatrix from 'constants/aclMatrix';

import { ReactComponent as WalletIcon } from 'assets/imgRevamp/Components_Icons_wallet.svg';
import { ReactComponent as FinanceIcon } from 'assets/imgRevamp/Components_Icons_cash.svg';
import { ReactComponent as IntegrationsIcon } from 'assets/imgRevamp/Components_Icons_puzzle.svg';
import { ReactComponent as BusinessIcon } from 'assets/imgRevamp/settings_teams_and_members.svg';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const rolesCodes = [
  { icon: FinanceIcon, code: 108 },
  { icon: WalletIcon, code: 104 },
  { icon: IntegrationsIcon, code: 101 },
  { icon: BusinessIcon, code: 102 },
  { icon: FinanceIcon, code: 208 },
  { icon: WalletIcon, code: 204 },
  { icon: IntegrationsIcon, code: 201 },
  { icon: BusinessIcon, code: 202 }
];

export const mapCodeToIcons = (code) => {
  return Object.values(rolesCodes).find((item) => item.code === code)?.icon;
};

export const checkRoleBannerModal = (type) => {
  return bannerModalPermission[type].includes(userInfo.user?.group?.name);
};

export const isAuthorized = (userRoles = '', accessRoles) => {
  return accessRoles?.includes(userRoles);
};

export const isWalletAuthorized = () => {
  const role = userInfo.user.group.name;
  return aclMatrix.WALLET_ACCESS.includes(role);
};

export const isFinanceAuthorized = () => {
  const role = userInfo.user.group.name;
  return aclMatrix.FINANCE_ACCESS.includes(role);
};

export const isBusinessSubAccount = () =>
  userInfo.user.group.name === BUSINESS_SUB_ACCOUNT;
