import { getCurrentUserCountryData } from 'constants/countries/countries-mapping';
import { bostaHttp } from 'http.js';

export const register = (regSrc, data) => {
  return bostaHttp.post(`/users/business/admin?regSrc=${regSrc}`, data);
};

export const sendCode = (data) => {
  return bostaHttp.post(`/users/business-admin/phone/confirm`, data);
};

export const sendCodePhone = (data) => {
  return bostaHttp.post(`/users/business-admin/phone/verify`, data);
};

export const updateVerifyPhone = (id, data) => {
  return bostaHttp.put(`/users/${id}/business`, data);
};

export const confirmVerifyEmail = (data) => {
  return bostaHttp.post(`/users/mail/confirm`, data);
};

export const sendVerificationCode = (data) => {
  return bostaHttp.post(`/users/mail/verify`, data);
};

export const getBusiness = (id) => {
  return bostaHttp.get(`/businesses/${id}/profile`);
};

export const postGetStartBusiness = (data) => {
  return bostaHttp.post(`/businesses`, data);
};

export const updateGetStartBusiness = (data, id) => {
  return bostaHttp.put(`/businesses/${id}`, data);
};

export const sendEmailForgetPassword = (email) => {
  return bostaHttp.post('/users/forget-password', { email });
};

export const login = (data, headers) => {
  return bostaHttp.post('/users/login', data, headers);
};

export const setNewPassword = (token, password) => {
  return bostaHttp.put(`/users/${token}/reset`, { password });
};

export const confirmChangeEmail = (data) => {
  return bostaHttp.post(`/businesses/verify-change-email`, data);
};

export const generateOTP = (id) => {
  return bostaHttp.post(`/businesses/${id}/generate-payment-otp`);
};

export const confirmOTP = (id, data) => {
  return bostaHttp.post(`/businesses/${id}/confirm-payment-otp`, data);
};

export const setShowBanner = (businessId, bannerName) => {
  const date = new Date();
  const value = JSON.parse(localStorage.getItem(bannerName));
  localStorage.setItem(
    bannerName,
    JSON.stringify({
      ...(value ? value : null),
      [businessId]: date.setDate(date.getDate() + 1)
    })
  );
};

export const getShowBanner = (businessId, bannerName) => {
  const value = JSON.parse(localStorage.getItem(bannerName));

  if (value && value[businessId]) {
    const date = Date.now();
    if (date > value[businessId]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const getUserInfoToken = (data) => {
  return bostaHttp.get('/users/login-details', { token: data });
};

export const submitFeedbackAboutBeta = (payload) => {
  return bostaHttp.put('/businesses/sendFeedback', payload);
};

export const updateFeatureConfiguration = (payload) => {
  return bostaHttp.put('/businesses/feature-configurations', payload);
};

export const getUserFullData = () => {
  return bostaHttp.get('/users/fullData');
};

export const getBusinessMembers = (id) => {
  return bostaHttp.get(`/businesses/${id}/users`);
};

export const deleteAccount = (reason, payload) => {
  return bostaHttp.delete(`/businesses?reason=${reason}`, payload);
};

export const getDeleteConditions = () => {
  return bostaHttp.get('/businesses/can-delete');
};

export const recoverAccount = (payload, headers) => {
  return bostaHttp.post(`/businesses/recover-account`, payload, headers);
};

export const oneTimeLogin = (data) => {
  return bostaHttp.post(`/users/one-time-login`, data);
};

export const getBusinessDonationInfo = () => {
  return bostaHttp.get(`/businesses/donations`);
};

export const getCountryTimeZone = () => {
  return bostaHttp.get(
    `/cities/country/time-zone/${getCurrentUserCountryData().id}`
  );
};

export const updateBankDetailsInfo = (data) => {
  return bostaHttp.post('/businesses/add-bank-info', data);
};

export const updateSecondBankInfo = (data) => {
  return bostaHttp.post('/businesses/add-second-bank-info', data);
};

export const changePassword = (payload) => {
  return bostaHttp.put('/users/change-password', payload);
};

export const updatePricingPlan = ({ userId, payload }) => {
  return bostaHttp.put(`/users/${userId}/business/pricing-tier`, payload);
};
