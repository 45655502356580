import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';

import BROTPCode from 'components/BROTPCode/BROTPCode';

import { ReactComponent as CloseIcon } from 'assets/imgRevamp/CloseIcon.svg';

import './BROTPCodeModal.less';

const BROTPCodeModal = ({
  visible,
  close,
  intl,
  confirmOTP,
  generateOTP,
  onSuccess,
  title,
  handleResendCode,
  subtitle,
  noCloseModal = true,
  phoneNumber
}) => {
  const handleCloseModal = () => {
    close();
  };

  return (
    <Modal
      visible={visible}
      onCancel={noCloseModal ? close : null}
      footer={null}
      closeIcon={noCloseModal ? <Icon component={CloseIcon} /> : null}
      wrapClassName={classnames('br-otp-modal__container', {
        'br-otp-modal__container-close-icon': !noCloseModal
      })}
    >
      <BROTPCode
        title={title}
        subtitle={subtitle}
        generateOTP={generateOTP}
        confirmOTP={confirmOTP}
        onSuccess={onSuccess}
        handleResendCode={handleResendCode}
        closeModal={handleCloseModal}
        phoneNumber={phoneNumber}
      />
    </Modal>
  );
};

export default injectIntl(BROTPCodeModal);
