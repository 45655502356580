import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Checkbox } from 'antd';
import classnames from 'classnames';

import { useModal } from 'contexts/modalProvider.context';

import TermsModal from 'components/SignUp/components/TermsModal/TermsModal';

import './TermsAndConditions.less';

const TermsAndConditions = ({
  intl,
  formRef,
  country,
  initialValue = false
}) => {
  const [termsRejected, setTermsRejected] = useState(false);

  const { openModal } = useModal();

  const handleTermsCheckedChange = ({ target: { checked } }) => {
    if (checked) {
      formRef?.current?.setFieldsValue({
        terms: false
      });

      openModal(TermsModal, {
        country,
        email: formRef?.current.getFieldValue('email'),
        onOk: () => {
          setTermsRejected(false);
          formRef?.current?.setFieldsValue({
            terms: true
          });
        }
      });
    } else {
      formRef?.current?.setFieldsValue({
        terms: false
      });
    }
  };

  return (
    <Form.Item
      className="br-signup__form__terms"
      name="terms"
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) =>
            value ? Promise.resolve() : Promise.reject(setTermsRejected(true))
        }
      ]}
      initialValue={initialValue}
    >
      <Checkbox onChange={handleTermsCheckedChange}>
        <span
          className={classnames({
            'br-sign-up__terms-rejected': termsRejected
          })}
        >
          {intl.formatMessage({
            id: 'sign_up.read_terms_first'
          })}
        </span>
        <span className="br-signup-terms-link">
          {intl.formatMessage({
            id: 'sign_up.terms_and_conditions'
          })}
        </span>
      </Checkbox>
    </Form.Item>
  );
};

export default injectIntl(TermsAndConditions);
