import dayjs from 'dayjs';

import { isUserHasFulfillmentAccess } from 'utils/features-access';
import {
  getMaxNumOfAttempts,
  getSmartFlyersLocalizationId,
  isSmartStickersAvailable
} from 'constants/countries/countries-mapping';
import {
  formatNumbersBasedOnLanguage,
  getLocaleFromLocalStorage
} from 'utils/intl-wrapper';
import { COUNTRY_CURRENCY } from './helpers';
import { BUSINESS_TYPES } from 'constants/business';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as ParcelIcon } from 'assets/bosta-icons/Parcel.svg';
import { ReactComponent as DocumentIcon } from 'assets/bosta-icons/Document.svg';
import { ReactComponent as BulkyIcon } from 'assets/bosta-icons/Bulky.svg';
import { ReactComponent as DeliverIcon } from 'assets/bosta-icons/Deliver disabled.svg';
import { ReactComponent as ExchangeIcon } from 'assets/bosta-icons/Exchange.svg';
import { ReactComponent as CashCollectionIcon } from 'assets/bosta-icons/Cash-Collection.svg';
import { ReactComponent as SignAndReturnIcon } from 'assets/bosta-icons/Sign-and-Return.svg';
import { ReactComponent as ReturnIcon } from 'assets/bosta-icons/Return.svg';
import { ReactComponent as CheckCircle } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as ClearCircle } from 'assets/bosta-icons/Circle-clear.svg';
import { ReactComponent as Clock } from 'assets/bosta-icons/filled-clock.svg';
import { ReactComponent as OrdersIcon } from 'assets/bosta-icons/Orders.svg';
import { ReactComponent as PhoneIcon } from 'assets/bosta-icons/phone-number.svg';
import { ReactComponent as HashIcon } from 'assets/bosta-icons/hash.svg';
import PrintingOptionA6 from 'assets/imgRevamp/printing-option-a6.png';
import PrintingOptionA4 from 'assets/imgRevamp/printing-option-a4.png';

export const PROMISE = {
  MET: 'met',
  NOT_MET: 'not_met',
  IN_PROGRESS: 'in_progress',
  NOT_STARTED: 'not_started'
};

export const FINAL_STATS = [
  'Delivered',
  'Returned_to_business',
  'Terminated',
  'Returned to business',
  'Delivery Confirmed'
];

export const MAP_DELIVERIES_CODES = {
  10: 'forward',
  15: 'cash_collection',
  20: 'return_to_origin',
  25: 'customer_return_pickup',
  30: 'exchange',
  35: 'sign_and_return',
  200: 'forward'
};

export const MAP_BEFORE_TYPE = {
  SEND: 10,
  EXCHANGE: 30,
  CASH_COLLECTION: 15,
  CUSTOMER_RETURN_PICKUP: 25,
  SIGN_AND_RETURN: 35,
  RTO: 20,
  FXF_SEND: 200
};

export const DELIVERY_STATES_CODES = {
  PICKUP_REQUESTED: 10,
  PICKUP_ORDER_CREATED: 11,
  ROUTE_ASSIGNED: 20,
  PICKED_UP_FROM_BUSINESS: 21,
  OUT_FOR_PICKUP: 22,
  PICKED_UP_FROM_CONSIGNEE: 23,
  RECEIVED_AT_WAREHOUSE: 24,
  IN_TRANSIT_BETWEEN_HUBS: 30,
  OUT_FOR_COLLECTION: 40,
  OUT_FOR_DELIVERY: 41,
  DELIVERED: 45,
  RETURNED_TO_BUSINESS: 46,
  EXCEPTION: 47,
  TERMINATED: 48,
  UNCOVERED_CITY: 49,
  COLLECTION_FAILED: 50,
  LOST: 100,
  DAMAGED: 101,
  INVESTIGATION: 102,
  ARCHIVED: 104,
  ON_HOLD: 105,
  RETURNED_TO_STOCK: 60
};

export const DELIVERY_CHILD_STATES_CODES = {
  AWAITING_BUSINESS_ACTION: 103,
  REJECTED_RETURN: 106
};

export const DELIVERY_EXCEPTIONS_CODES = {
  ALLOW_OPEN_PACKAGE: 4,
  BUSINESS_CANCELD: 6,
  CUSTOMER_REFUSED: 8,
  STAR_NOT_CALLING: 11,
  ADDRESS_NOT_CLEAR: 13,
  WRONG_PHONE_NUMBER: 14,
  REJECTED_RETURN: 24
};

export const ORDER_TYPES_STRINGS = [
  { label: fmt({ id: 'orders.order_types.forward' }), value: 'SEND' },
  {
    label: fmt({ id: 'orders.order_types.cash_collection' }),
    value: 'CASH_COLLECTION'
  },
  { label: fmt({ id: 'orders.order_types.exchange' }), value: 'EXCHANGE' },
  {
    label: fmt({ id: 'orders.order_types.customer_return_pickup' }),
    value: 'CUSTOMER_RETURN_PICKUP'
  },
  {
    label: fmt({ id: 'orders.order_types.sign_and_return' }),
    value: 'SIGN_AND_RETURN'
  }
];

export const COD_OPTIONS = {
  COD: 'cod',
  REFUND: 'refund',
  WITH_OUT: 'without'
};

export const PACKAGE_TYPES = [
  { label: 'parcel', value: 'Parcel', icon: ParcelIcon },
  { label: 'document', value: 'Document', icon: DocumentIcon },
  { label: 'bulky', value: 'Bulky', icon: BulkyIcon }
];

export const MASS_UPLOAD_ERRORS = {
  UNCOVERED_CITY: 'Uncovered City',
  CITY_NOT_FOUND: 'City Not Found',
  ZONE_NOT_FOUND: 'Zone Not Found',
  DISTRICT_NOT_FOUND: 'District Not Found',
  WRONG_SKU: 'This BostaSKU doesnt relate to this business',
  INVALID_SKU_QUANTITY: 'This BostaSKU doesnt have more available quantity',
  UNCOVERED_ZONE: 'Uncovered drop off or pickup address'
};

export const INITIAL_STATES = [10, 11];
export const ALLOWED_STATES_TO_RESCHEDULE_ORDER = [20, 21, 22, 24, 30, 41, 47];
export const ALLOWED_STATES_TO_CANCEL_ORDER = [
  ...INITIAL_STATES,
  20,
  21,
  22,
  24,
  30,
  41,
  47
];

export const DELIVERY_STATE_ENABELD_COD = [
  ...INITIAL_STATES,
  20,
  21,
  24,
  25,
  30
];

export const DELIVERY_STATE_ENABLED_COD_CHANGE_REQUEST = [
  DELIVERY_STATES_CODES.OUT_FOR_DELIVERY,
  DELIVERY_STATES_CODES.EXCEPTION
];

export const DELIVERY_TYPES_CODES = {
  SEND: 10,
  CASH_COLLECTION: 15,
  RETURN_TO_ORIGIN: 20,
  CUSTOMER_RETURN_PICKUP: 25,
  EXCHANGE: 30,
  SIGN_AND_RETURN: 35,
  FXF_SEND: 200
};

export const PACKAGE_TYPES_BULKY = ['Light Bulky', 'Heavy Bulky'];

export const MAX_IMAGE_SIZE_UPLOAD = 5;

export const MAX_IMAGE_UPLOAD = 3;

export const ORDER_TYPES = ({ businessType, isInternationalOrder } = {}) => [
  {
    title: isInternationalOrder ? 'international' : 'deliver',
    className: 'deliver',
    icon: DeliverIcon,
    value: DELIVERY_TYPES_CODES.SEND,
    isUserHasAccess: true
  },
  {
    title: 'exchange',
    className: 'exchange',
    icon: ExchangeIcon,
    value: DELIVERY_TYPES_CODES.EXCHANGE,
    isUserHasAccess: true
  },
  {
    title: 'return',
    className: 'return',
    icon: ReturnIcon,
    value: DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP,
    isUserHasAccess: true
  },
  {
    title: 'cash_collection',
    className: 'cash-collection',
    icon: CashCollectionIcon,
    value: DELIVERY_TYPES_CODES.CASH_COLLECTION,
    isUserHasAccess: true
  },
  {
    title: 'sign_and_return',
    className: 'sign-and-return new-badge',
    icon: SignAndReturnIcon,
    value: DELIVERY_TYPES_CODES.SIGN_AND_RETURN,
    isUserHasAccess: businessType === BUSINESS_TYPES.B2B
  }
];

export const SELECTED_INVENTORY_COLUMNS = [
  {
    title: fmt({
      id: `new_order.order_details.order_types.select_from_inventory.inventory_table.products`
    }),
    dataIndex: 'products',
    width: 300
  },
  {
    title: fmt({
      id: `new_order.order_details.order_types.select_from_inventory.inventory_table.quantity`
    }),
    dataIndex: 'quantity',
    width: 60
  },
  {
    title: fmt({
      id: `new_order.order_details.order_types.select_from_inventory.inventory_table.price`
    }),
    dataIndex: 'price',
    width: 100
  },
  {
    dataIndex: 'delete',
    width: 30
  }
];

export const INVENTORY_MODAL_COLUMNS = [
  {
    title: fmt({
      id: `new_order.order_details.order_types.select_from_inventory.inventory_table.products`
    }),
    dataIndex: 'products',
    width: 250
  },
  {
    title: fmt({
      id: `new_order.order_details.order_types.select_from_inventory.inventory_table.quantity`
    }),
    dataIndex: 'quantity',
    width: 120
  },
  {
    title: fmt({
      id: `new_order.order_details.order_types.select_from_inventory.inventory_table.price`
    }),
    dataIndex: 'price',
    width: 100
  }
];

export const DEFAULT_FXF_DROPDOWN_ITEM_COUNT = 3;

export const DELETED = fmt({
  id: `orders.order_new_statues.deleted`
});
export const NEW = fmt({
  id: `orders.order_new_statues.new`
});
export const RETURN_TO_ORIGIN = fmt({
  id: 'orders.order_new_statues.returned_to_origin'
});
export const CREATE = 'CREATE';
export const DELETED_CODE = 48;
export const ROLE_BUSINESS_ADMIN_LOGS = 'BUSINESS_ADMIN';

export const INTERNATIONAL_SUCCESS_FINAL_STATES = [60];

export const ORDER_SUCCESS_FINAL_STATES = [
  45, // Delivered
  46, // Returned to business,
  ...INTERNATIONAL_SUCCESS_FINAL_STATES
];

export const ORDER_FAILED_FINAL_STATES = [
  // 48, // Terminated
  100, // Lost
  101 // Damaged
  // 104 // Archived
];

export const ORDER_FINAL_STATES = [
  ...ORDER_SUCCESS_FINAL_STATES,
  ...ORDER_FAILED_FINAL_STATES
];

export const ALL_ORDER_FINAL_STATES = [
  ...ORDER_SUCCESS_FINAL_STATES,
  48, // Terminated
  100, // Lost
  101, // Damaged
  104 // Archived
];

export const SHIPMENTS_COLUMNS = [
  {
    title: fmt({
      id: `orders.table_columns.order_id`
    }),
    dataIndex: 'order_id',
    fixed: 'left',
    width: 75
  },
  {
    title: fmt({
      id: `orders.table_columns.type`
    }),
    dataIndex: 'type',
    width: 115
  },
  {
    title: fmt({
      id: `orders.table_columns.customer_info`
    }),
    dataIndex: 'customerInfo',
    width: 130
  },
  {
    title: fmt({
      id: `orders.table_columns.location`
    }),
    dataIndex: 'location',
    width: 130
  },
  {
    title: fmt({
      id: `orders.table_columns.amount`
    }),
    dataIndex: 'cod',
    width: 135
  },
  {
    title: fmt({
      id: `orders.table_columns.status`
    }),
    dataIndex: 'status',
    width: 143
  },
  {
    title: fmt({
      id: `orders.table_columns.attempts`
    }),
    dataIndex: 'attempts',
    width: 80
  },
  {
    title: fmt({
      id: `orders.table_columns.date`
    }),
    dataIndex: 'date',
    width: 120
  },
  {
    title: '',
    dataIndex: 'action',
    render: (action) => (
      <div
        className="br-table-actions-btn"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {action}
      </div>
    ),
    width: 50
  }
];

export const ATTEMPTS_TOOLTIP_CONTENT = (exceptions = []) => {
  if (!exceptions?.length) {
    return [
      {
        content: fmt({
          id: `attempts_reasons.empty`
        })
      }
    ];
  }
  exceptions = exceptions.reverse();

  const attemptsArray = Array.from(
    Array(getMaxNumOfAttempts().DELIVERY).keys()
  );

  const tooltipContentTitle = ['first', 'second', 'third', 'fourth'];

  return attemptsArray
    .map(
      (attempt) =>
        exceptions.length > attempt &&
        attempt < attemptsArray.length && {
          number: attempt + 1,
          title: fmt({
            id: `orders_listing.${tooltipContentTitle[attempt]}_attempt_title`
          }),
          content: fmt({
            id: `attempts_reasons.${exceptions[attempt]?.code}`
          })
        }
    )
    .reverse();
};

export const FULFILLMENT_OPTIONS = [
  {
    label: fmt({ id: 'orders_listing.filters.fulfilled_from_inventory' }),
    value: true
  },
  {
    label: fmt({ id: 'orders_listing.filters.not_fulfilled_from_inventory' }),
    value: false
  }
];

export const ON_HOLD_CODE = 105;
export const WAITING_BUSINESS_ACTION = 103;

export const QUICK_FILTERS = [
  { label: fmt({ id: 'orders_listing.quick_filters.all' }), value: [] },
  {
    label: fmt({ id: 'orders_listing.quick_filters.new' }),
    value: ['10.1:F,E,S', '10.2']
  },
  {
    label: fmt({ id: 'orders_listing.filters.in_progress' }),
    value: [
      '30',
      '21',
      '23',
      '42,43',
      '24.5',
      '47.5',
      '10:C',
      '11',
      '25',
      '105',
      '49',
      '102',
      '20'
    ]
  },
  {
    label: fmt({ id: 'orders_listing.quick_filters.heading_to_customer' }),
    value: ['41:F,FS', '22', '40']
  },
  {
    label: fmt({ id: 'orders_listing.quick_filters.awaiting_your_action' }),
    value: ['125.5,103']
  },
  {
    label: fmt({ id: 'orders_listing.quick_filters.successful' }),
    value: ['45', '46:S,E,C'],
    valueMapping: ['45', '46:C', '46:E', '46:S']
  },
  {
    label: fmt({ id: 'orders_listing.quick_filters.unsuccessful' }),
    value: ['46:R', '104', '100', '101', '50', '48', '60']
  },
  {
    label: fmt({ id: 'rejected_return_ticket_modal.title' }),
    value: ['24&106', '105&107']
  },
  {
    label: fmt({ id: 'orders_listing.quick_filters.fulfilled' }),
    value: [200],
    valueMapping: ['25'],
    isUserAuthorized: isUserHasFulfillmentAccess
  }
];

export const COMPARE_PRICING_COLUMNS = [
  {
    title: fmt({
      id: 'tracker_bar.compare_modal.columns.package_sizes'
    }),
    dataIndex: 'PackageSizes',
    key: 'PackageSizes'
  },
  {
    title: fmt({
      id: 'tracker_bar.compare_modal.columns.deliver'
    }),
    dataIndex: 'SEND',
    key: 'SEND'
  },
  {
    title: fmt({
      id: 'tracker_bar.compare_modal.columns.exchange'
    }),
    dataIndex: 'EXCHANGE',
    key: 'EXCHANGE'
  },
  {
    title: fmt({
      id: 'tracker_bar.compare_modal.columns.return'
    }),
    dataIndex: 'CUSTOMER_RETURN_PICKUP',
    key: 'CUSTOMER_RETURN_PICKUP'
  },
  {
    title: fmt({
      id: 'tracker_bar.compare_modal.columns.cash_collection'
    }),
    dataIndex: 'CASH_COLLECTION',
    key: 'CASH_COLLECTION'
  }
];
export const COMPARE_PRICING_FILTERS = [
  {
    label: fmt({
      id: 'tracker_bar.compare_modal.cities.cairo'
    }),
    value: 1
  },
  {
    label: fmt({
      id: 'tracker_bar.compare_modal.cities.alexandria'
    }),
    value: 2
  },
  {
    label: fmt({
      id: 'tracker_bar.compare_modal.cities.delta'
    }),
    value: 3
  },
  {
    label: fmt({
      id: 'tracker_bar.compare_modal.cities.near_upper'
    }),
    value: 4
  },
  {
    label: fmt({
      id: 'tracker_bar.compare_modal.cities.far_upper'
    }),
    value: 5
  },
  {
    label: fmt({
      id: 'tracker_bar.compare_modal.cities.north_coast'
    }),
    value: 6
  },
  {
    label: fmt({
      id: 'tracker_bar.compare_modal.cities.sinai'
    }),
    value: 7
  }
];

export const COMPARE_PRICING_SIZES = {
  BULKY: 'bulky',
  LIGHT_BULKY: 'light bulky',
  HEAVY_BULKY: 'heavy bulky'
};

export const BUSINESS_UPGRADE_KEYS = {
  UPGRADE_POPUP_VIEWED: 'isUpgradePricingTierPopUpViewed',
  DOWNGRADE_POPUP_VIEWED: 'isDowngradePricingTierPopUpViewed'
};

export const MIN_CONSIGNEE_GOOD_RATE = 6;

export const PACKAGE_TYPES_OPTIONS = [
  {
    value: 'Parcel',
    label: fmt({
      id: `new_order.order_details.package_types.parcel`
    })
  },
  {
    value: 'Document',
    label: fmt({
      id: `new_order.order_details.package_types.document`
    })
  },
  {
    value: 'Light Bulky',
    label: fmt({
      id: `settings.pricing_plan.sizes.light_bulky`
    })
  },
  {
    value: 'Heavy Bulky',
    label: fmt({
      id: `settings.pricing_plan.sizes.heavy_bulky`
    })
  }
];

export const RESCHEDULE_ORDER_CUTOFF_TIME = 21;

export const RESCHEDULE_DATE_FORMAT = 'dddd, DD MMM YYYY';

export const ALLOWED_STATES_FOR_BUSINESS_EDIT_ORDER = [
  ...INITIAL_STATES,
  20,
  21,
  22,
  24,
  25,
  30,
  41,
  47
];

export const PACKING_GUIDE_LINK = () =>
  `https://bosta.freshdesk.com/${
    getLocaleFromLocalStorage() === 'en' ? 'en' : 'ar'
  }/support/solutions/articles/36000414624-how-to-pack-your-orders`;

export const COD_PLACEHOLDER = '0.00';

export const PRINTING_OPTIONS = {
  A4: 'A4',
  A6: 'A6'
};

export const PRINTING_OPTIONS_MODAL = [
  {
    value: PRINTING_OPTIONS.A6,
    image: PrintingOptionA6,
    size: fmt({
      id: 'orders_listing.printing_options.a6'
    }),
    printerType: fmt({
      id: 'orders_listing.printing_options.thermal_printers'
    })
  },
  {
    value: PRINTING_OPTIONS.A4,
    image: PrintingOptionA4,
    size: fmt({
      id: 'orders_listing.printing_options.a4'
    }),
    printerType: fmt({
      id: 'orders_listing.printing_options.standard_printers'
    })
  }
];

export const COUNTRY_NUMBER_OF_ATTEMPTS = {
  EG: 3,
  SA: 2
};

export const REJECTED_ORDER_TICKET_CATEGORY = 'Return Issue';
export const COD_MIN_MAX = {
  MIN: 1,
  MAX: 30000
};

export const OTHER = 'Others';

export const REJECTION_REASONS_STRINGS = {
  DAMAGED: 'Damaged order',
  MISSING: 'Missing Item',
  OPENED: 'Opened shipment',
  MIXED: 'Mixed order',
  EMPTY: 'Empty Package'
};

export const REJECTION_REASONS = [
  {
    value: REJECTION_REASONS_STRINGS.DAMAGED,
    label: fmt({
      id: 'rejected_return_ticket_modal.rejection_reasons.damaged_order'
    })
  },
  {
    value: REJECTION_REASONS_STRINGS.MISSING,
    label: fmt({
      id: 'rejected_return_ticket_modal.rejection_reasons.missing_items'
    })
  },
  {
    value: REJECTION_REASONS_STRINGS.OPENED,
    label: fmt({
      id: 'rejected_return_ticket_modal.rejection_reasons.opened_package'
    })
  },
  {
    value: REJECTION_REASONS_STRINGS.MIXED,
    label: fmt({
      id: 'rejected_return_ticket_modal.rejection_reasons.mixed_order'
    })
  },
  {
    value: REJECTION_REASONS_STRINGS.EMPTY,
    label: fmt({
      id: 'rejected_return_ticket_modal.rejection_reasons.empty_package'
    })
  }
];

export const REFUND_MAX_AMOUNT = -2000;

export const TICKET_REQUESETER = {
  Customer: 'Customer'
};

export const PUDO_STATES = {
  RECEIVED_AT_WAREHOUSE: 'received at warehouse',
  READY_TO_PICK: 'ready to pick',
  DELIVERD: 'delivered',
  CLOSED: 'closed',
  UNDELIVERED: 'undelivered',
  RTO_IN_TRANSIT: 'rtointransit'
};

export const PUDO_HUB_TYPE = 'THIRD_PARTY_HUB';

export const PUDO_PROVIDER = 'PUDO_PROVIDER';

export const PACKAGE_MODES = {
  FULFILLED: {
    label: fmt({ id: 'new_order.order_details.package.fulfilled' }),
    value: 1
  },
  PICKUP: {
    label: fmt({ id: 'new_order.order_details.package.pickup' }),
    value: 2
  }
};
export const FULFILLED = 'FULFILLED';
export const PICKUP = 'PICKUP';

export const REQUESTS_TYPE = {
  CHANGE_COD: 'CHANGE_COD'
};

export const COD_REQUEST_BANNER_MAPPING = ({
  requestDetails,
  cod,
  rejectionReason
}) => ({
  10: {
    icon: <Clock />,
    type: 'info',
    text: fmt(
      { id: 'new_order.request_cod_change.request_banner.pending' },
      {
        oldAmount: (
          <span className="body-medium">
            {fmt(
              { id: COUNTRY_CURRENCY },
              { cod: formatNumbersBasedOnLanguage(cod) }
            )}
          </span>
        ),
        newAmount: (
          <span className="body-medium">
            {fmt(
              { id: COUNTRY_CURRENCY },
              { cod: formatNumbersBasedOnLanguage(requestDetails.codAmount) }
            )}
          </span>
        )
      }
    )
  },
  60: {
    icon: <ClearCircle />,
    type: 'danger',
    text: fmt(
      { id: 'new_order.request_cod_change.request_banner.rejected' },
      {
        oldAmount: (
          <span className="body-medium">
            {fmt(
              { id: COUNTRY_CURRENCY },
              { cod: formatNumbersBasedOnLanguage(cod) }
            )}
          </span>
        ),
        newAmount: (
          <span className="body-medium">
            {fmt(
              { id: COUNTRY_CURRENCY },
              { cod: formatNumbersBasedOnLanguage(requestDetails.codAmount) }
            )}
          </span>
        ),
        reason: rejectionReason,
        TextDiv: (children) => (
          <div className="br-order-details__cod-change-rejection-reason">
            {children}
          </div>
        ),
        span: (children) => <span className="body-medium">{children}</span>
      }
    )
  },
  100: {
    icon: <CheckCircle />,
    type: 'success',
    text: fmt(
      { id: 'new_order.request_cod_change.request_banner.approved' },
      {
        newAmount: (
          <span className="body-medium">
            {fmt(
              { id: COUNTRY_CURRENCY },
              { cod: formatNumbersBasedOnLanguage(requestDetails.codAmount) }
            )}
          </span>
        )
      }
    )
  }
});

export const ORDER_SEARCH_TYPES = {
  MOBILE_PHONES: 'mobilePhones',
  TRACKING_NUMBERS: 'trackingNumbers',
  BUSINESS_REFERENCE: 'businessReference',
  BARCODE_REF: 'barcodeRef'
};

export const ORDERS_SEARCH_BY_LABELS = {
  [ORDER_SEARCH_TYPES.TRACKING_NUMBERS]: fmt({
    id: 'orders_listing.actions.order_id'
  }),
  [ORDER_SEARCH_TYPES.MOBILE_PHONES]: fmt({
    id: 'orders_listing.actions.phone_number'
  }),
  [ORDER_SEARCH_TYPES.BUSINESS_REFERENCE]: fmt({
    id: 'orders_listing.actions.order_ref'
  }),
  ...(isSmartStickersAvailable() && {
    [ORDER_SEARCH_TYPES.BARCODE_REF]: fmt({
      id: getSmartFlyersLocalizationId(
        'orders_listing.actions.smart_sticker_number'
      )
    })
  })
};

export const ORDERS_SEARCH_BY_OPTIONS = [
  {
    value: ORDER_SEARCH_TYPES.MOBILE_PHONES,
    label: ORDERS_SEARCH_BY_LABELS.mobilePhones
  },
  {
    value: ORDER_SEARCH_TYPES.TRACKING_NUMBERS,
    label: ORDERS_SEARCH_BY_LABELS.trackingNumbers
  },
  {
    value: ORDER_SEARCH_TYPES.BUSINESS_REFERENCE,
    label: ORDERS_SEARCH_BY_LABELS.businessReference
  },
  ...(isSmartStickersAvailable()
    ? [
        {
          value: ORDER_SEARCH_TYPES.BARCODE_REF,
          label: ORDERS_SEARCH_BY_LABELS.barcodeRef
        }
      ]
    : [])
];

export const ORDERS_SEARCH_EMPTY_STATE_ICONS = {
  [ORDER_SEARCH_TYPES.MOBILE_PHONES]: <PhoneIcon />,
  [ORDER_SEARCH_TYPES.TRACKING_NUMBERS]: <OrdersIcon />,
  [ORDER_SEARCH_TYPES.BUSINESS_REFERENCE]: <HashIcon />,
  [ORDER_SEARCH_TYPES.BARCODE_REF]: <HashIcon />
};

export const ORDERS_SEARCH_PLACEHOLDERS_KEYS = {
  [ORDER_SEARCH_TYPES.MOBILE_PHONES]: 'search_mobile_placeholder',
  [ORDER_SEARCH_TYPES.TRACKING_NUMBERS]: 'search_tracking_placeholder',
  [ORDER_SEARCH_TYPES.BUSINESS_REFERENCE]: 'search_reference_placeholder',
  [ORDER_SEARCH_TYPES.BARCODE_REF]: getSmartFlyersLocalizationId(
    'search_smart_sticker_placeholder'
  )
};

export const DRAFT_ORDERS_PATH = 'draft';
export const SHIPPING_ORDERS_PATH = 'shipping';
export const PERCENTAGE_DISCOUNT = 'PERCENTAGE_DISCOUNT';

export const FIRST_LINE_MIN_LENGTH = 5;

export const MULTI_PACKAGES_COUNT = {
  MIN: 2,
  MAX: 10
};

export const WEIGHT_EQUATION_DIVIDER = 5000;

export const INFO_LIST = (weightThreshold, costForExtraWeight) => ({
  EMPTY: [
    fmt(
      { id: 'volumetric_calculator.info.info_1' },
      {
        weightThreshold: weightThreshold,
        costForExtraWeight: costForExtraWeight
      }
    ),
    fmt({ id: 'volumetric_calculator.info.info_2' }),
    fmt({ id: 'volumetric_calculator.info.info_3' }),
    fmt({ id: 'volumetric_calculator.info.info_4' })
  ],
  ACTIVE: [
    fmt({ id: 'volumetric_calculator.info.info_5' }),
    fmt(
      { id: 'volumetric_calculator.info.info_6' },
      {
        weightThreshold: weightThreshold,
        costForExtraWeight: costForExtraWeight
      }
    )
  ]
});

export const BULK_REJECTED_RETURNS_TABLE_KEY = {
  REJECTION_REASON: 'rejectionReason',
  ORDER_COST: 'orderCost',
  DESCRIPTION: 'description',
  IMAGES: 'images'
};

export const BULK_REJECTED_RETURNS_TICKETS_COLUMNS = [
  {
    title: fmt({ id: 'rejected_return_ticket_modal.form_labels.order_id' }),
    dataIndex: 'trackingNumber',
    width: 40,
    render: (trackingNumber, record) => (
      <div className="br-bulk-rejected-returns__modal-tracking-number">
        <span className="body-medium">{trackingNumber}</span>
        <span className="caption-lg">
          {dayjs(record.updatedAt).format('h:mm A')}
        </span>
      </div>
    )
  },
  {
    title: fmt({
      id: 'rejected_return_ticket_modal.form_labels.images'
    }),
    dataIndex: BULK_REJECTED_RETURNS_TABLE_KEY.IMAGES,
    editable: true,
    width: 40
  },
  {
    title: fmt({
      id: 'rejected_return_ticket_modal.form_labels.rejection_reason'
    }),
    dataIndex: BULK_REJECTED_RETURNS_TABLE_KEY.REJECTION_REASON,
    editable: true,
    width: 80
  },
  {
    title: fmt({
      id: 'rejected_return_ticket_modal.form_labels.order_cost'
    }),
    dataIndex: BULK_REJECTED_RETURNS_TABLE_KEY.ORDER_COST,
    editable: true,
    width: 80
  },
  {
    title: fmt({
      id: 'rejected_return_ticket_modal.form_labels.description'
    }),
    dataIndex: BULK_REJECTED_RETURNS_TABLE_KEY.DESCRIPTION,
    editable: true,
    width: 80
  }
];

export const REJECTED_RETURNS_BULK_TICKETS_STEPS = {
  TICKET_DATA: 'TICKET_DATA',
  PACKAGING_DATA: 'PACKAGING_DATA'
};

export const REJECTED_RETURN_SEARCH_CODE = '24&106';

export const ADD_PICKUP_LOCATION_GUIDE_LIST_LABELS = [
  'pickup_your_order',
  'deliver_any_returns',
  'receive_your_materials'
];

export const MAX_ITEM_VALUE = 50000;

export const FILES_EXTENTIONS = {
  PDF: 'pdf'
};

export const IS_ITEM_TOUR_VIEWED_KEY = 'isItemValueTourViewed';

export const CANCELLATION_REASONS = [
  {
    value: 'SHIP_WITH_OTHER_COMPANY',
    title: fmt({ id: 'marketplace.cancellation_reasons.other_company' })
  },
  {
    value: 'CUSTOMER_REQUESTED_TO_CANCEL',
    title: fmt({ id: 'marketplace.cancellation_reasons.customer_cancel' })
  },
  {
    value: 'OUT_OF_STOCK',
    title: fmt({ id: 'marketplace.cancellation_reasons.out_of_stock' })
  },
  {
    value: 'WRONG_PRICE',
    title: fmt({ id: 'marketplace.cancellation_reasons.wrong_price' })
  },
  {
    value: 'WRONG_SHIPPING_FEES',
    title: fmt({ id: 'marketplace.cancellation_reasons.wrong_fees' })
  },
  {
    value: 'OTHER',
    title: fmt({ id: 'marketplace.cancellation_reasons.other' })
  }
];
