export const COUNTRIES = [
  {
    id: '60e4482c7cb7d4bc4849c4d5',
    placeholder: '1078286109',
    codeNumber: '+20',
    codeNumberArabic: '20+',
    codeName: 'EG',
    code: 'EG',
    displayName: 'Egypt +20',
    display: true,
    name: 'Egypt',
    arabicName: 'مصر',
    removeZeroWithCodeNumber: true
  },
  {
    id: 'eF-3f9FZr',
    placeholder: '12345678',
    codeNumber: '+966',
    codeNumberArabic: '966+',
    codeName: 'SA',
    code: 'SA',
    displayName: 'Saudi Arabia +966',
    display: true,
    name: 'Saudi Arabia',
    arabicName: 'السعودية',
    removeZeroWithCodeNumber: true
  },
  {
    id: '60e4482c7cb7d4bc4849c4d4',
    placeholder: '12345678',
    codeNumber: '+971',
    codeNumberArabic: '971+',
    codeName: 'AE',
    code: 'AE',
    displayName: 'United Arab Emirates +971',
    display: true,
    name: 'United Arab Emirates',
    arabicName: 'الإمارات العربية المتحدة'
  },
  {
    id: '10e4482c7cb7d4bc4849c4d7',
    placeholder: '65123456',
    codeNumber: '+974',
    codeNumberArabic: '974+',
    codeName: 'QA',
    code: 'QA',
    displayName: 'Qatar +974',
    display: true,
    name: 'Qatar',
    arabicName: 'قطر'
  }
];

export const QATAR_COUNTRY_DATA = {
  id: '10e4482c7cb7d4bc4849c4d7',
  placeholder: '65123456',
  codeNumber: '+974',
  codeNumberArabic: '974+',
  codeName: 'QA',
  code: 'QA',
  displayName: 'Qatar +974',
  display: true,
  name: 'Qatar',
  arabicName: 'قطر'
};

export const OMAN_COUNTRY_DATA = {
  id: '10e4482c7cb7d4bc4849c4dF',
  placeholder: '65123456',
  codeNumber: '+968',
  codeNumberArabic: '968+',
  codeName: 'OM',
  code: 'OM',
  displayName: 'Oman +968',
  display: true,
  name: 'Oman',
  arabicName: 'عمان'
};

export const KUWAIT_COUNTRY_DATA = {
  id: '60e4482c7cb7d4bc4849c4d6',
  placeholder: '65123456',
  codeNumber: '+965',
  codeNumberArabic: '965+',
  codeName: 'KW',
  code: 'KW',
  displayName: 'Kuwait +965',
  display: true,
  name: 'Kuwait',
  arabicName: 'الكويت'
};

export const ALL_COUNTRIES = [
  ...COUNTRIES,
  QATAR_COUNTRY_DATA,
  OMAN_COUNTRY_DATA,
  KUWAIT_COUNTRY_DATA
];

export const COUNTRIES_CODE_NAME = {
  EG: 'EG',
  SA: 'SA',
  AE: 'AE',
  QA: 'QA',
  OM: 'OM',
  KW: 'KW'
};

export const URL_FLAGS =
  'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/';
