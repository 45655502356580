import { COUNTRIES } from 'constants/country-data';

export const END_DATES_KEYS = ['confirmedAtEnd', 'dateRangeEnd'];
export const isSaudi = () =>
  JSON.parse(localStorage.getItem('userInfo'))?.user?.country?.code ===
    COUNTRIES[1].codeName || false;

export const USER_COUNTRY_CODE = JSON.parse(localStorage.getItem('userInfo'))
  ?.user?.country?.code;
/*
 * Cairo Timezone -> Africa/Cairo
 * Riyadh Timezone -> Asia/Riyadh
 */

export const COUNTRIES_TIME_ZONE = {
  egypt: 'Africa/Cairo',
  ksa: 'Asia/Riyadh'
};

export const COUNTRY_CURRENCY_MAPPER = {
  EG: {
    AMOUNT: 'common.egp_amount',
    AMOUNT_MODIFIED: 'common.EGP_modified'
  },
  SA: {
    AMOUNT: 'common.saudi_amount',
    AMOUNT_MODIFIED: 'common.SAR_modified'
  },
  AE: {
    AMOUNT: 'common.aed_amount',
    AMOUNT_MODIFIED: 'common.aed_amount'
  },
  QA: {
    AMOUNT: 'common.QAR_amount',
    AMOUNT_MODIFIED: 'common.QAR_modified'
  }
};

export const COUNTRY_CURRENCY =
  COUNTRY_CURRENCY_MAPPER[USER_COUNTRY_CODE]?.AMOUNT;

export const COUNTRY_CURRENCY_MODIFIED =
  COUNTRY_CURRENCY_MAPPER[USER_COUNTRY_CODE]?.AMOUNT_MODIFIED;

export const PHONE_PLACEHOLDER = isSaudi()
  ? 'form.saudi_arabia_phone_placeholder'
  : 'form.egypt_phone_placeholder';

export const SECONDRY_PHONE_PLACEHOLDER = isSaudi()
  ? 'form.saudi_arabia_secondry_phone_placeholder'
  : 'form.egypt_secondry_phone_placeholder';

// export const STREET_PLACEHOLDER = isSaudi()
//   ? 'form.saudi_arabia_street_placeholder'
//   : 'form.egypt_street_placeholder';

const COUNTRY_STREET_PLACEHOLDER = {
  SA: 'form.saudi_arabia_street_placeholder',
  EG: 'form.egypt_street_placeholder',
  AE: 'form.emirati_street_placeholder',
  QA: 'form.qatar_street_placeholder',
  OM: 'form.oman_street_placeholder',
  KW: 'form.kuwait_street_placeholder'
};

export const STREET_PLACEHOLDER = (countryCode) =>
  COUNTRY_STREET_PLACEHOLDER[countryCode || USER_COUNTRY_CODE];

export const compareHelper = (textOne, textTwo) => {
  const one = textOne.title || textOne.name;
  const two = textTwo.title || textTwo.name;
  if (one.toLowerCase() < two.toLowerCase()) {
    return -1;
  } else if (one.toLowerCase() > two.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const TABLE_PAGE_SIZE_OPTIONS = [50, 100, 200];

export const getCurrentConfigKeyName = (method) => {
  let key;
  switch (method) {
    case 'mada':
      key = 'localCardConfigurations';
      break;
    case 'apple':
      key = 'walletConfigurations';
      break;
    case 'valu':
      key = 'valuConfigurations';
      break;
    default:
      key = 'cardConfigurations';
      break;
  }
  return key;
};

export const removeZeroFraction = (value) => {
  return value % 1 === 0 ? parseInt(value) : value;
};

export const DAYS_OF_WEEK = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
};
