import { Modal } from 'antd';

import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import { logout } from 'utils';
import { sendSegment } from 'utils/segment';
import { getSignInPath } from 'utils/login';
import { getSignUpPath } from 'utils/signup';
import { useModal } from 'contexts/modalProvider.context';
import { sendCode, sendCodePhone, updateVerifyPhone } from 'services/auth';

import BROTPCode from 'components/BROTPCode/BROTPCode';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import UpdatePhoneModal from 'components/SignUp/components/UpdatePhoneModal/UpdatePhoneModal';

import './VerifyPhoneModal.less';

const VerifyPhoneModal = ({
  intl,
  close,
  history,
  userID,
  email,
  phone,
  initialCountry,
  onOpen = () => {},
  withSelect = true,
  normalClose = false,
  onSuccess = () => {},
  ...props
}) => {
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [wrongOTP, setWrongOTP] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { openModal } = useModal();

  const handleCloseTerms = () => {
    sendSegment('E_SIGNUP_PHONE_VERIFICATION_SIGNUP');
    normalClose ? close() : logout(history.push(getSignInPath()));
  };

  useEffect(() => {
    setPhoneNumber(phone);
  }, [phone, email]);

  useEffect(() => {
    sendSegment('E_PHONE_VERIFICATION_PAGE_VIEWED', { email: email });
    onOpen();
  }, []);

  const handleSubmit = async (values) => {
    try {
      await sendCode({
        phone: phoneNumber,
        token: values
      });
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      userInfo.user.isPhoneVerified = true;
      userInfo.user.profile.phone = phoneNumber;
      localStorage.setItem('userInfo', JSON.stringify(userInfo));

      sendSegment('Step1c_Confirmed-OTP');
      onSuccess();
      sendSegment('E_SIGNUP_VERIFICTION_CODE_ENTERED', {
        email: userInfo.user.emails[0].address
      });
      sendSegment('E_BUSINESS_VERIFICATION_EMAIL_SENT', {
        email: userInfo.user.emails[0].address
      });

      close();
      if (normalClose) {
        //for handling accept invitation
        window.location.replace('/');
      } else {
        window.location.replace(getSignUpPath());
      }
    } catch (error) {
      setWrongOTP(true);
      setConfirmDisabled(true);
      setConfirmLoading(false);
    }
  };

  const resendCode = async () => {
    try {
      await sendCodePhone({ phone: phoneNumber });
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  const handleEditPhoneClick = () => {
    sendSegment('E_SIGNUP_EDIT_PHONE_NUMBER', { email });
    openModal(UpdatePhoneModal, {
      id: userID,
      email: email,
      setPhone: setPhoneNumber,
      withSelect: withSelect,
      initialCountry
    });
  };

  return (
    <Modal
      {...props}
      footer={
        <>
          <BRButton
            type="basic"
            onClick={handleCloseTerms}
            label={intl.formatMessage({
              id: 'common.cancel'
            })}
          />
          <BRButton
            type="primary"
            className="br-registration-form-card__submit-btn"
            loading={confirmLoading}
            disabled={confirmDisabled}
            label={intl.formatMessage({
              id: 'common.confirm'
            })}
          />
        </>
      }
      wrapClassName="br-verify-phone-modal"
    >
      <div className="verify-phone-modal__body">
        <div className="verify-phone__opt-container">
          <BROTPCode
            title={intl.formatMessage({
              id: 'sign_up.verify_phone.title'
            })}
            headerClassName="br-verify-phone-modal__header"
            isInternalComponent
            phoneNumber={phoneNumber}
            wrongOTP={wrongOTP}
            setWrongOTP={setWrongOTP}
            confirmOTP={handleSubmit}
            handleResendCode={() => resendCode()}
            setConfirmDisabled={setConfirmDisabled}
            setConfirmLoading={setConfirmLoading}
          />
        </div>

        <div className="verify-phone-modal__wrong-number">
          <span className="body">
            {intl.formatMessage({
              id: 'sign_up.verify_phone.not_your_phone'
            })}
          </span>
          <BRButton
            type="link-color"
            onClick={handleEditPhoneClick}
            label={intl.formatMessage({
              id: 'international_shipping_in.checkout.change'
            })}
          />
        </div>
      </div>
    </Modal>
  );
};
export default injectIntl(VerifyPhoneModal);
