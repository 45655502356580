import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

import { login, recoverAccount, sendEmailForgetPassword } from 'services/auth';
import {
  PRODUCTION_URL,
  BETA_URL_REDIRECT,
  ERROR_CODES,
  TWO_FACTOR_AUTH_ENABLED_STATUS_CODE
} from 'constants/login';
import { sendSegment } from 'utils/segment';
import { DEVICE_FINGERPRINT_KEY, DEVICE_ID_KEY } from 'constants/common';
import { handleFingerPrint } from 'utils/security';

import { notify } from 'components/Notify/Notify';
import BROTPCodeModal from 'components/BROTPCodeModal/BROTPCodeModal';

const handleAfterLoginSuccessfully = ({
  userInfo,
  location,
  history,
  intl
}) => {
  if (userInfo?.headers?.[DEVICE_ID_KEY]) {
    const deviceId = userInfo.headers[DEVICE_ID_KEY];
    localStorage.setItem(DEVICE_ID_KEY, deviceId);
  }

  sendSegment('E_BUSINESS_SIGNIN', {
    email: userInfo.user.emails[0].address,
    roles: userInfo.user.roles[0],
    'Business Name':
      userInfo.user.businessAdminInfo !== undefined
        ? userInfo.user.businessAdminInfo.businessName
        : null,
    phone: userInfo.user.profile.phone || '',
    Name: `${userInfo.user.profile.firstName} ${
      userInfo.user.profile.lastName ? userInfo.user.profile.lastName : ''
    }`,
    businessId:
      userInfo.user.businessAdminInfo?.businessId !== undefined
        ? {
            id: userInfo.user.businessAdminInfo?.businessId,
            name: userInfo.user.businessAdminInfo?.businessName
          }
        : null,
    'Business Type': userInfo.user.monthlyShipmentVolume || ''
  });
  if (
    window.location.hostname === PRODUCTION_URL &&
    userInfo.user.businessAdminInfo &&
    userInfo.user.businessAdminInfo?.isBetaUser
  ) {
    window.open(
      `${BETA_URL_REDIRECT}${userInfo.token.replace('Bearer ', '')}`,
      '_parent'
    );
  }
  const { user = {} } = userInfo;
  if (userInfo) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    localStorage.setItem('newToken', userInfo.token);
    localStorage.setItem(
      'isRegisteredHekouky',
      !!userInfo?.user?.businessAdminInfo?.hekoukyProfileInfo?.isRegistered
    );

    window?.analytics?.identify(user.emails[0].address, {
      'User Email': user.emails[0].address,
      Permission: user?.group?.name,
      'Business Name':
        user.businessAdminInfo !== undefined
          ? user?.businessAdminInfo?.businessName
          : null,
      Phone: user.profile.phone || '',
      'Business Type': user.monthlyShipmentVolume || '',
      'Dashboard Language': intl.locale
    });

    sendSegment('Step1_Successful-sign-in');
  }
  if (location.search === '?payment') {
    history.push('/settings/payment-info');
  } else if (location.state && location.state.pathName) {
    const {
      state: { pathName, pathSearch = '', pathState = undefined }
    } = location;

    window.history.replaceState(
      pathState,
      null,
      `${pathName}${pathSearch ? `?${pathSearch}` : ''}`
    );

    window.location.reload();
  } else {
    window.location.reload();
  }
};

export const userLogin = async ({
  email,
  password,
  setIsLoading,
  history,
  intl,
  restoreAccount = false,
  openModal
}) => {
  const { location } = history;
  try {
    setIsLoading(true);
    const payload = {
      email: email.toLowerCase().trim(),
      password: password
    };
    const deviceFingerPrint = await handleFingerPrint();
    const deviceId = localStorage.getItem(DEVICE_ID_KEY);

    const serviceToCall = restoreAccount ? recoverAccount : login;
    const headers = {
      ...(deviceFingerPrint && {
        [DEVICE_FINGERPRINT_KEY]: deviceFingerPrint
      }),
      ...(deviceId && { [DEVICE_ID_KEY]: deviceId })
    };
    let userInfo = await serviceToCall(payload, {
      headers
    });
    if (userInfo.status === TWO_FACTOR_AUTH_ENABLED_STATUS_CODE) {
      setIsLoading(false);
      openModal(BROTPCodeModal, {
        title: intl.formatMessage({
          id: 'settings.payment_method.otp_screen.title'
        }),
        noCloseModal: false,
        confirmOTP: async (otp) => {
          try {
            userInfo = await serviceToCall(
              { ...payload, otp },
              {
                headers
              }
            );
          } catch (error) {
            notify({ msg: error.message });
          }
        },
        handleResendCode: async () => {
          await serviceToCall(payload, {
            headers
          });
        },

        onSuccess: () => {
          handleAfterLoginSuccessfully({ userInfo, location, history, intl });
        },
        phoneNumber: payload.email
      });
    } else {
      handleAfterLoginSuccessfully({ userInfo, location, history, intl });
    }
  } catch (error) {
    const { errorCode, data } = error;
    setIsLoading(false);
    if (
      [ERROR_CODES.USER_NOT_FOUND, ERROR_CODES.NOT_VALID].includes(errorCode)
    ) {
      notify({
        msg: intl.formatMessage({ id: 'login.not_valid_input' }),
        error
      });
    } else if (errorCode === ERROR_CODES.DELETED_ACCOUNT) {
      history.push({
        pathName: '/signin',
        state: {
          firstName: data.profile.firstName,
          lastName: data.profile.lastName,
          userType: data.regSrc,
          email,
          password,
          restoreAccount: true
        }
      });
    } else if (errorCode === ERROR_CODES.PASSWORD_EXPIRED) {
      await sendEmailForgetPassword(email);
      notify({
        msg: intl.formatMessage({
          id: 'login.set_new_passowrd.expired_error_message'
        })
      });
      history.push({
        pathname: '/confirm-reset-password',
        state: {
          email,
          isPasswordExpired: true
        }
      });
    } else {
      notify({ msg: error.message, error });
    }
  }
};

export const getSignInPath = () => '/signin';
