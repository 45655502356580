export const IS_SMART_STICKERS_AVAILABLE = {
  EG: true,
  SA: true
};

export const SMART_FLYERS_KEY = (key) => ({
  EG: key,
  SA: key + '_ksa',
  AE: key,
  QA: key
});

export const SMART_FLYERS_AVAILABLE = {
  EG: false,
  SA: true
};

export const MULTI_PACKAGE_AVAILABLE = {
  EG: false,
  SA: true,
  DEFAULT: false
};
